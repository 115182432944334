import * as React from 'react';
import translate from 'app/i18n/translate';
import styles from './Header.scss';

const Header = () => {
  return (
    <div className={styles.wrapper} data-test-id="header">
      <div className={styles.titleArea}>
        <img
          className={styles.logo}
          alt="Ansarada"
          src="https://static.ansarada.com/logos/0.2.0/ansarada-logomark.svg"
        />

        <div
          data-ansarada-ccd
          className={styles.title}
          data-test-id="self-registration-header-title"
        >
          {translate('SelfRegistrationHeader')}
        </div>
      </div>
    </div>
  );
};

export default Header;
