// @flow

import type {
  SelfRegistrationFormValuesType,
  SelfRegistrationSessionType,
} from 'app/layout/selfRegistration/selfRegistrationTypes';

export const MOCK_SELF_REGISTRATION_SESSION: SelfRegistrationSessionType = {
  isValid: true,
  errorCode: 0,
  linkGuid: 'test-guid',
  dataroomProjectName: 'test-room',
};

export const initialFormValues: SelfRegistrationFormValuesType = {
  email: '',
  firstName: '',
  lastName: '',
  jobTitle: '',
  selectedJobTitle: {
    jobTitleName: '',
    jobTitleId: -1,
  },
  profession: '',
  jobTitleOther: '',
  location: '',
  selectedLocation: {
    locationName: '',
    locationId: -1,
  },
  phoneNumber: '',
  company: '',
  additionalEmails: '',
};

export const MOCK_FORM_VALUES = initialFormValues;
