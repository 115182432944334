// @flow

import * as React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import store from 'app/store';
import SelfRegistration from 'app/layout/selfRegistration/SelfRegistration';

const renderSelfRegistration = () => {
  const root = document.getElementById('spa-root');

  ReactDOM.render(
    <Provider store={store}>
      <SelfRegistration />
    </Provider>,
    root,
  );
};

renderSelfRegistration();
