// @flow

import * as React from 'react';
import SelfRegistrationFormContainer from 'app/layout/selfRegistration/selfRegistrationForm/SelfRegistrationFormContainer';
import SelfRegistrationSuccess from 'app/layout/selfRegistration/selfRegistrationResult/SelfRegistrationSuccess';
import SelfRegistrationError from 'app/layout/selfRegistration/selfRegistrationResult/SelfRegistrationError';
import type { AppStateType } from 'app/types/appStateTypes';
import { connect } from 'react-redux';
import type { SelfRegistrationStatusType } from 'app/layout/selfRegistration/selfRegistrationTypes';
import { getJSONFromScript } from 'app/utils/dom';
import Header from 'app/layout/selfRegistration/header/Header';
import BannerMessages from 'app/components/bannerMessages/BannerMessages';

export type PropsType = {
  registrationStatus: SelfRegistrationStatusType,
};

export const SelfRegistration = ({ registrationStatus }: PropsType) => {
  const selfRegistrationSession: any = getJSONFromScript('selfRegistrationInitData');

  let component = <SelfRegistrationError />;

  if (selfRegistrationSession.isValid) {
    if (registrationStatus === 'pending') {
      component = (
        <SelfRegistrationFormContainer selfRegistrationSession={selfRegistrationSession} />
      );
    }

    if (registrationStatus === 'success') {
      component = <SelfRegistrationSuccess />;
    }
  }

  return (
    <>
      <BannerMessages />
      <Header />
      {component}
    </>
  );
};

const mapStateToProps = (state: AppStateType) => ({
  registrationStatus: state.selfRegistration.registrationStatus,
});

export default connect<AppStateType, _, *, *>(mapStateToProps)(SelfRegistration);
