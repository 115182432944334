// @flow

import * as React from 'react';
import { Hero } from '@ansarada/ace-react';
import translate from 'app/i18n/translate';
import HeroImg from './hero-img.png';
import styles from './SelfRegistrationResult.scss';

const SelfRegistrationSuccess = () => {
  return (
    <div className={styles.container}>
      <Hero
        alt="Ansarada"
        content={[
          <p key="info">{translate('YouWillReceiveAConfirmationInYourEmailAboutNextSteps')}</p>,
        ]}
        src={HeroImg}
        title={translate('RegistrationRequestSubmitted')}
      />
    </div>
  );
};

export default SelfRegistrationSuccess;
