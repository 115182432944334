// @flow

import * as React from 'react';
import {
  HelpLink,
  Icon,
  icon,
  FormattedContentBlock,
  FormGrid,
  FormGridCell,
  FormGridRow,
  Link,
  SubmitButton,
  TextInput,
  TextArea,
} from '@ansarada/ace-react';
import { Form } from 'formik';
import classnames from 'classnames';
import translate, { translateWithFormat } from 'app/i18n/translate';
import { LABELS } from 'app/layout/selfRegistration/validationSchema';
import styles from 'app/layout/selfRegistration/selfRegistrationForm/SelfRegistrationForm.scss';
import LocationInput from 'app/layout/selfRegistration/locationInput/LocationInput';
import JobTitleInput from 'app/layout/selfRegistration/jobTitleInput/JobTitleInput';
import type { AppDispatch } from 'app/types/appStateTypes';
import type {
  SelfRegistrationErrorsType,
  SelfRegistrationFormValuesType,
} from 'app/layout/selfRegistration/selfRegistrationTypes';
import { isEmptyObject, isShallowEqual } from 'app/utils/object';
import { ACTION_TYPES } from 'app/constants';
import { getJSONFromScript } from 'app/utils/dom';

export type PropsType = {
  errors: Object,
  handleBlur: Function,
  setFieldValue: Function,
  setErrors: Function,
  touched: Object,
  values: SelfRegistrationFormValuesType,
  registrationErrors: SelfRegistrationErrorsType,
  dispatch: AppDispatch,
  submitRegistrationLoading: boolean,
};
type StateType = {
  showAdditionalEmailsHint: boolean,
  dataroomName: string,
};
export class SelfRegistrationForm extends React.Component<PropsType, StateType> {
  constructor(props: PropsType) {
    super(props);
    const selfRegistrationInitData: any = getJSONFromScript('selfRegistrationInitData');
    this.state = {
      showAdditionalEmailsHint: false,
      dataroomName: selfRegistrationInitData.dataroomName,
    };
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps: any) {
    const { registrationErrors, setErrors, dispatch } = this.props;
    if (
      !isEmptyObject(nextProps.registrationErrors) &&
      !(registrationErrors && isShallowEqual(nextProps.registrationErrors, registrationErrors))
    ) {
      setErrors(nextProps.registrationErrors);
      dispatch({
        type: ACTION_TYPES.SELF_REGISTRATION.SUBMIT_REGISTRATION.RESET_ERROR,
      });
    }
  }

  toggleAdditionalEmailsHint = (event: SyntheticMouseEvent<>) => {
    event.preventDefault();
    this.setState({ showAdditionalEmailsHint: !this.state.showAdditionalEmailsHint });
  };

  render() {
    const {
      errors,
      handleBlur,
      setFieldValue,
      touched,
      values,
      submitRegistrationLoading,
    } = this.props;

    const isShowError = (fieldName: string) => touched[fieldName] && errors[fieldName];
    const onTextBlur = (field: string, e: any) => {
      setFieldValue(field, e.target.value.trim());
      handleBlur(e);
    };
    const additionalEmailErrors =
      errors.additionalEmails?.length > 0 ? errors.additionalEmails.split(',') : [];

    return (
      <Form className={styles.container}>
        <FormattedContentBlock className={styles.formDescription}>
          <h2>{translate('RegisterYourInterest')}</h2>
          <div>
            {translateWithFormat('RegisterYourInterest_SentenceCase', {
              dataRoomName: this.state.dataroomName,
            })}
          </div>
        </FormattedContentBlock>

        <FormGrid>
          <FormGridRow key="firstName">
            <FormGridCell
              labelText={LABELS.firstName}
              errors={isShowError('firstName') ? errors.firstName : ''}
              errorPosition="Bottom"
            >
              <TextInput
                data-test-id="first-name"
                name="firstName"
                type="Text"
                value={values.firstName}
                onChangeValue={value => setFieldValue('firstName', value)}
                onBlur={e => onTextBlur('firstName', e)}
                status={isShowError('firstName') && 'Bad'}
                data-ansarada-ccd
              />
            </FormGridCell>
          </FormGridRow>

          <FormGridRow key="lastName">
            <FormGridCell
              labelText={LABELS.lastName}
              errors={isShowError('lastName') ? errors.lastName : ''}
              errorPosition="Bottom"
            >
              <TextInput
                data-test-id="last-name"
                name="lastName"
                type="Text"
                value={values.lastName}
                onChangeValue={value => setFieldValue('lastName', value)}
                onBlur={e => onTextBlur('lastName', e)}
                status={isShowError('lastName') && 'Bad'}
                data-ansarada-ccd
              />
            </FormGridCell>
          </FormGridRow>

          <FormGridRow key="email">
            <FormGridCell
              labelText={LABELS.email}
              errors={isShowError('email') ? errors.email : ''}
              errorPosition="Bottom"
            >
              <TextInput
                data-test-id="email"
                name="email"
                type="Text"
                value={values.email}
                onChangeValue={value => setFieldValue('email', value)}
                onBlur={e => onTextBlur('email', e)}
                status={isShowError('email') && 'Bad'}
                data-ansarada-ccd
              />
            </FormGridCell>
          </FormGridRow>

          <FormGridRow key="phoneNumber">
            <FormGridCell
              labelText={LABELS.phoneNumber}
              errors={isShowError('phoneNumber') ? errors.phoneNumber : ''}
              errorPosition="Bottom"
            >
              <TextInput
                data-test-id="phone-number"
                name="phoneNumber"
                type="Tel"
                defaultValue={values.phoneNumber}
                onChangeValue={value => setFieldValue('phoneNumber', value)}
                onBlur={e => onTextBlur('phoneNumber', e)}
                status={isShowError('phoneNumber') && 'Bad'}
                data-ansarada-ccd
              />
            </FormGridCell>
          </FormGridRow>

          <LocationInput
            isShowError={isShowError}
            setFieldValue={setFieldValue}
            errors={errors}
            values={values}
            onTextBlur={onTextBlur}
          />

          <FormGridRow key="company">
            <FormGridCell
              labelText={LABELS.company}
              errors={isShowError('company') ? errors.company : ''}
              errorPosition="Bottom"
            >
              <TextInput
                data-test-id="company"
                name="company"
                type="Text"
                value={values.company}
                onChangeValue={value => setFieldValue('company', value)}
                onBlur={e => onTextBlur('company', e)}
                status={isShowError('company') && 'Bad'}
                data-ansarada-ccd
              />
            </FormGridCell>
          </FormGridRow>

          <FormGridRow key="jobTitle">
            <FormGridCell>
              <JobTitleInput
                isShowError={isShowError}
                setFieldValue={setFieldValue}
                errors={errors}
                values={values}
                onTextBlur={onTextBlur}
              />
            </FormGridCell>
          </FormGridRow>

          <FormGridRow key="additionalEmails">
            <div className={styles.additionalEmails}>
              <FormGridCell labelText={`${LABELS.additionalEmails} (${translate('Optional')})`}>
                <HelpLink
                  href="#"
                  icon={
                    <Icon
                      className={styles.additionalEmailsHelpIcon}
                      onClick={this.toggleAdditionalEmailsHint}
                      glyph={icon.Glyphs.StatusHelp}
                    />
                  }
                />
                {this.state.showAdditionalEmailsHint ? (
                  <div className={styles.additionalEmailHelpInfo}>
                    <FormattedContentBlock>
                      <p>
                        <small>{translate('TeamMemberEmailsInfo')}</small>
                      </p>
                    </FormattedContentBlock>
                  </div>
                ) : (
                  <> </>
                )}
              </FormGridCell>
            </div>

            <FormGridCell
              errors={isShowError('additionalEmails') ? additionalEmailErrors : ''}
              errorPosition="Bottom"
            >
              <TextArea
                data-test-id="additionalEmails"
                name="additionalEmails"
                value={values.additionalEmails}
                onChangeValue={value => setFieldValue('additionalEmails', value)}
                onBlur={e => onTextBlur('additionalEmails', e)}
                status={isShowError('additionalEmails') && 'Bad'}
                data-ansarada-ccd
              />
            </FormGridCell>
          </FormGridRow>

          <FormGridRow>
            <FormGridCell>
              <div className={styles.textCenter}>
                <SubmitButton
                  data-test-id="submit-button"
                  variant="Primary"
                  loading={submitRegistrationLoading}
                >
                  {translate('Register')}
                </SubmitButton>
              </div>
            </FormGridCell>
          </FormGridRow>

          <FormGridRow>
            <FormGridCell>
              <FormattedContentBlock>
                <p className={classnames(styles.textCenter, styles.terms)}>
                  {translate('BySubmittingThisForm')}{' '}
                  <Link href="https://www.ansarada.com/privacy" target="_blank">
                    {translate('PrivacyPolicy')}
                  </Link>
                  ,{' '}
                  <Link href="https://www.ansarada.com/terms-conditions" target="_blank">
                    {translate('TermsAndConditions')}
                  </Link>{' '}
                  {translate('And_SentenceCase')}{' '}
                  <Link href="https://www.ansarada.com/gdpr" target="_blank">
                    {translate('GDPRInformation')}
                  </Link>
                  .
                </p>
              </FormattedContentBlock>
            </FormGridCell>
          </FormGridRow>
        </FormGrid>
      </Form>
    );
  }
}

export default SelfRegistrationForm;
