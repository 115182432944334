// @flow

import * as React from 'react';
import { Formik } from 'formik';
import validationSchema from 'app/layout/selfRegistration/validationSchema';
import type { AppDispatch, AppStateType } from 'app/types/appStateTypes';
import { connect } from 'react-redux';
import { ACTION_TYPES } from 'app/constants';
import type {
  SelfRegistrationErrorsType,
  SelfRegistrationFormValuesType,
  SelfRegistrationPayloadType,
  SelfRegistrationSessionType,
} from 'app/layout/selfRegistration/selfRegistrationTypes';
import { SelfRegistrationForm } from 'app/layout/selfRegistration/selfRegistrationForm/SelfRegistrationForm';
import { initialFormValues } from 'app/layout/selfRegistration/constants';
import { normalizeEmails } from 'app/pages/teams/peopleFormDialog/peopleFormDialogActions';
import { Column, Columns } from '@ansarada/ace-react';
import styles from './SelfRegistratrionFormContainer.scss';

const mapValuesToPayload = (
  values: SelfRegistrationFormValuesType,
  selfRegistrationSession: SelfRegistrationSessionType,
): SelfRegistrationPayloadType => {
  return {
    firstName: values.firstName,
    lastName: values.lastName,
    email: values.email,
    phoneNumber: values.phoneNumber,
    locationId: values.selectedLocation?.locationId,
    jobTitleId: values.selectedJobTitle?.jobTitleId,
    jobTitleGroupOther: values.profession,
    jobTitleOther: values.jobTitleOther,
    linkGuid: selfRegistrationSession.linkGuid,
    dataRoomProjectName: selfRegistrationSession.dataroomProjectName,
    company: values.company,
    additionalEmails: normalizeEmails(values.additionalEmails),
  };
};

export type PropsType = {
  submitRegistrationLoading: boolean,
  dispatch: AppDispatch,
  selfRegistrationSession: SelfRegistrationSessionType,
  registrationErrors: SelfRegistrationErrorsType,
};

export const SelfRegistrationFormContainer = ({
  dispatch,
  selfRegistrationSession,
  registrationErrors,
  submitRegistrationLoading,
}: PropsType) => {
  return (
    <div className={styles.container}>
      <Columns spacing="None">
        <Column alignment="None">
          <Formik
            initialValues={initialFormValues}
            initialErrors={registrationErrors}
            validationSchema={validationSchema}
            onSubmit={values => {
              dispatch({
                type: ACTION_TYPES.SELF_REGISTRATION.SUBMIT_REGISTRATION.FETCH,
                data: mapValuesToPayload(values, selfRegistrationSession),
              });
            }}
          >
            {props => (
              <SelfRegistrationForm
                {...props}
                registrationErrors={registrationErrors}
                submitRegistrationLoading={submitRegistrationLoading}
                dispatch={dispatch}
              />
            )}
          </Formik>
        </Column>
      </Columns>
    </div>
  );
};

const mapStateToProps = (state: AppStateType) => ({
  submitRegistrationLoading: state.selfRegistration.submitRegistrationLoading,
  registrationErrors: state.selfRegistration.registrationErrors,
});

export default connect<AppStateType, _, *, *>(mapStateToProps)(SelfRegistrationFormContainer);
